import React, { useState, useCallback } from 'react';
import './FileUploadForm.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const CustomDropzone = ({ onDrop, field, errors, selectedFileName, setSelectedFile, isSkipChecked, setSkipChecked }) => {
  const handleSkipChange = (e) => {
    const isChecked = e.target.checked;
    setSkipChecked(isChecked);

    // Clear the selected file and file name
    if (isChecked) {
      onDrop([], field); // Pass an empty array to clear the selected file
    } else {
      // Clear the error when Skip is unchecked
      errors[field] && delete errors[field];
    }
  };

  const handleAttachClick = (e) => {
    if (isSkipChecked) {
      e.preventDefault(); // Prevent opening the file explorer if "Skip" is checked
    }
  };

  return (
    <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '0.25rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <div style={{ flex: '60%' }}>
          <input
            type="text"
            value={selectedFileName || ''}
            className='document-input-field'
            readOnly
            onClick={() => setSelectedFile(field)}
            style={{ width: '100%' }}
            disabled={isSkipChecked}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flex: '20%', marginRight: '0' }}>
          <label
            className={`attach-button-container btn btn-primary${isSkipChecked ? ' disabled' : ''}`}
            style={{ marginTop: '0', display: 'flex' }}
            onClick={handleAttachClick}
          >
            <AttachFileIcon style={{ display: 'flex', transform: 'rotate(215deg)', fontSize: '1rem', margin: '0' }} />
            Attach
            <input
              type="file"
              accept='.pdf, .jpg, .jpeg, .png'
              onChange={(e) => onDrop(e.target.files, field)}
              style={{ display: 'none' }}
              disabled={isSkipChecked}
            />
          </label>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flex: '20%' }}>
          <label className="skip-checkbox">
            <input
              type="checkbox"
              onChange={handleSkipChange}
              checked={isSkipChecked}
            />
            <span style={{position: 'relative', bottom: '0.2rem'}}>Skip</span>
          </label>
        </div>
      </div>
      <div style={{ width: '100%' }} className="error-message">
        {errors[field] && !isSkipChecked && <span className="text-danger">{errors[field]}</span>}
      </div>
    </div>
  );
};

const FileUploadForm = () => {
  const [errors, setErrors] = useState({});
  const [selectedFileNames, setSelectedFileNames] = useState({});
  const [skipCheckedState, setSkipCheckedState] = useState({});

  const onDrop = useCallback((acceptedFiles, field) => {
    const file = acceptedFiles[0];

    if (!file) {
      setErrors({ ...errors, [field]: 'Please select a file.' });
      setSelectedFileNames({ ...selectedFileNames, [field]: null });
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setErrors({ ...errors, [field]: 'File size exceeds 5MB limit.' });
      setSelectedFileNames({ ...selectedFileNames, [field]: null });
      return;
    }

    const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
    if (!acceptedTypes.includes(file.type)) {
      setErrors({ ...errors, [field]: 'Unsupported file type.' });
      setSelectedFileNames({ ...selectedFileNames, [field]: null });
      return;
    }

    // Clear any previous errors
    setErrors({ ...errors, [field]: null });

    setSelectedFileNames({ ...selectedFileNames, [field]: file.name });

    // Automatically upload the file to the server here
    const formData = new FormData();
    formData.append('file', file);

    fetch('/upload', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        alert('File uploaded successfully.');
      })
      .catch((error) => {
        alert('Error uploading the file.');
        console.error(error);
      });
  }, [errors, selectedFileNames]);

  const documentTypes = [
    { field: 'panDocument', label: 'PAN Document' },
    { field: 'cinDocument', label: 'CIN Document' },
    { field: 'gstDocument', label: 'GST Document' },
    { field: 'pfCertificate', label: 'PF Certificate' },
    { field: 'cancelledCheque', label: 'Cancelled Cheque' },
    { field: 'otherDocument', label: 'Other Document' },
  ];

  const setSelectedFile = (field) => {
    const inputElement = document.getElementById(field);
    if (inputElement) {
      inputElement.click();
    }
  };

  return (
    <div className="container mt-0">
      {documentTypes.map((docType) => (
        <div className="row mt-2" key={docType.field}>
          <div className="col-5 col-md-2 document-label">
            <label htmlFor={docType.field}>{docType.label}</label>
          </div>
          <div className="col-5 col-md-5 d-flex flex-column">
            <CustomDropzone
              onDrop={onDrop}
              field={docType.field}
              errors={errors}
              selectedFileName={selectedFileNames[docType.field]}
              setSelectedFile={() => setSelectedFile(docType.field)}
              isSkipChecked={skipCheckedState[docType.field] || false}
              setSkipChecked={(isChecked) => setSkipCheckedState({ ...skipCheckedState, [docType.field]: isChecked })}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FileUploadForm;
