// LanguageSelection.js
import React from 'react';
import { useLanguage } from './LanguageContext'; // Assuming you have a LanguageContext for managing language

function LanguageSelection() {
  const { setLanguage } = useLanguage();

  const handleLanguageChange = (event) => {
    console.log("Entered handleLangugeChange");
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    changePageLanguage(newLanguage); // Trigger the function to change the page language
    console.log(window.google.translate);

  }

    // Function to change the page language
  const changePageLanguage = (newLanguage) => {
    console.log(newLanguage);
    console.log("Entered changePageLanguge");
    if (window.google && window.google.translate) {
      window.google.translate.translatePage(newLanguage);
    }

  };

  return (
    <div>
      <label htmlFor="target-language">Select Language:</label>
      <select id="target-language" onChange={handleLanguageChange}>
        <option value="en">English</option>
        <option value="es">Spanish</option>
        <option value="fr">French</option>
        {/* Add more language options as needed */}
      </select>
    </div>
  );
}

export default LanguageSelection;
