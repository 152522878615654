// VendorContext.js
import React, { createContext, useContext, useState } from 'react';

const VendorContext = createContext();

/*export function VendorProvider({ children }) {
  const [vendorNo, setVendorNo] = useState(null);
  const [vendorStatus, setVendorStatus] = useState(null);

  const setVendorNumber = (newVendorNo, newVendorStatus) => {
    setVendorNo(newVendorNo);
    setVendorStatus(newVendorStatus);
  };

  return (
    <VendorContext.Provider value={{ vendorNo, vendorStatus, setVendorNumber }}>
      {children}
    </VendorContext.Provider>
  );
}*/

export function VendorProvider({ children }) {
  const [vendorNo, setVendorNo] = useState(() => {
    // Attempt to get vendorNo from local storage
    const storedVendorNo = localStorage.getItem('vendorNo');
    return storedVendorNo || null;
  });

  const [vendorStatus, setVendorStatus] = useState(null);

  const setVendorNumber = (newVendorNo, newVendorStatus) => {
    setVendorNo(newVendorNo);
    setVendorStatus(newVendorStatus);
    // Save vendorNo to local storage
    localStorage.setItem('vendorNo', newVendorNo);
  };

  return (
    <VendorContext.Provider value={{ vendorNo, vendorStatus, setVendorNumber }}>
      {children}
    </VendorContext.Provider>
  );
}

export function useVendorContext() {
  const context = useContext(VendorContext);
  if (!context) {
    throw new Error('useVendorContext must be used within a VendorProvider');
  }
  return context;
}
