import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import './Login.css';
//import { useLanguage } from './LanguageContext';
import LanguageSelection  from './LanguageSelection'

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    //const { selectedLanguage, setLanguage } = useLanguage();
    const navigate = useNavigate();
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);

    useEffect(() => {
        // Check if the user is already logged in (token exists)
        const token = localStorage.getItem('token');
        if (token) {
            // Redirect to the home page or any other authenticated route
            navigate('/admin'); // Change '/home' to the appropriate route
        }
    }, [navigate]);

   /* const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
      };
      */

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRecaptchaVerify = (response) => {
        // This callback will be called when reCAPTCHA is successfully verified
        setRecaptchaVerified(true);
    };

    const [errorMessages, setErrorMessages] = useState({
        recaptcha: '',
        userNotRegistered: '',
        otherErrors: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        setErrorMessages({
            recaptcha: '',
            userNotRegistered: '',
            otherErrors: '',
        });

        if (!recaptchaVerified) {
            // reCAPTCHA is not verified, set the recaptcha error message
        setErrorMessages({
            ...errorMessages,
            recaptcha: 'Please verify that you are not a robot.',
        });
        return;
        }

        console.log('Email:', formData.email);
        console.log('Password:', formData.password);


        try {
            // Your login logic here...
            // If the reCAPTCHA is verified, you can proceed with login.
            const response = await axios.post('http://localhost:8080/api/users/login', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                try {
                    const data = response.data;

                    // Store the JWT token in your application state or local storage
                    // Example using local storage:
                    console.log(data.token);
                    localStorage.setItem('token', data.token);

                    // Navigate to the home page
                    //navigate('/admin'); // Use the navigate function
                    if (data.successfulLogin) {
                        // Navigate to the registration3 page if login is successful
                        navigate('/registration3');
                    } else {
                        // Handle the case where the email and password are correct but user-specific conditions are not met
                    setErrorMessages({
                        ...errorMessages,
                        otherErrors: 'Login succeeded, but user-specific conditions are not met. Redirecting to login page.',
                    });
                    // You can also redirect to the login page here
                    navigate('/login');
                    }
                } catch (error) {
                    console.error('Error parsing response JSON:', error);
                    // Handle the error, e.g., display an error message to the user
                }
            } 
            
            else if (response.status === 404) {
                // Handle the case where the email does not exist
                setErrorMessages({
                    ...errorMessages,
                    userNotRegistered: 'User is not registered. Please sign up.',
                });
            }
            else {
                // Handle login failure, e.g., display an error message
            setErrorMessages({
                ...errorMessages,
                otherErrors: 'Login failed',
            });
            }
        } catch (error) {
            setErrorMessages({
                ...errorMessages,
                otherErrors: 'Network error',
            });
            console.error('Network error:', error);
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className='left-login' id='leftLoginPart'>
                                <p>Logo and Quote Content</p>
                    </div>
                    <div className='right-login' id="rightLoginPart">                        
                        <div className="card my-custom-card">
                            
                            
                        <LanguageSelection />
                            <div className="card-header">
                                <h4>Login</h4>
                            </div>
                            <div className="card-body text-center">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-align">
                                        <div className="form-group">
                                            <label htmlFor="email" className="left-align-label mb-2">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control input-field"
                                                id="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                maxLength="50"
                                                required
                                            />
                                            {errorMessages.userNotRegistered && <p className="error-message">{errorMessages.userNotRegistered}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="password" className="left-align-label mt-2 mb-2">
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control input-field"
                                                id="password"
                                                name="password"
                                                placeholder="Enter Password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                minLength="8"
                                                maxLength="50"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* Center the ReCAPTCHA widget */}
                                    <div className="mt-3">
                                        <div className="recaptcha-container">
                                            <ReCAPTCHA
                                                sitekey="6LdzylcpAAAAAEUk_33yLn0kC68MflX8HFKZ6Wwr"
                                                onChange={handleRecaptchaVerify}
                                            />
                                        </div>
                                        {errorMessages.recaptcha && <p className="error-message">{errorMessages.recaptcha}</p>}
                                    </div>

                                    <button type="submit" className="btn btn-primary btn-block mt-3">
                                        Login
                                    </button>
                                    {errorMessages.otherErrors && <p className="error-message">{errorMessages.otherErrors}</p>}

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;