import React, { useState, useEffect } from 'react';
import './VerificationTable.css';

const initialVerificationData= {
    "Company Name": {
        value: "Sria Infotech",
        source: "MCA",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Date of Incorporation": {
        value: "23/09/2001",
        source: "MCA",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Class of Company": {
        value: "Pvt Ltd",
        source: "MCA",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Registered Address - PinCode": {
        value: "505526",
        source: "MCA",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Registered Office Address": {
        value: "Miyapur, Hyderabad",
        source: "MCA",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "CIN/LLPIN Status": {
        value: "Active",
        source: "MCA",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "PAN Status": {
        value: "Active",
        source: "NSDL",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "GST Status": {
        value: "Cancelled suo-moto",
        source: "GST",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "GST Company Name": {
        value: "Sria Infotech",
        source: "GST",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "GST Address": {
        value: "Miyapur, Hyderabad",
        source: "GST",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Establishment Status": {
        value: "Alloted",
        source: "EPFO",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Registration Status On ECR Portal": {
        value: "Permanent Login Created by Owner on ECR Portal",
        source: "EPFO",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Post Coverage Status": {
        value: "",
        source: "EPFO",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Excemption Status": {
        value: "Unexempted",
        source: "EPFO",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Actionable Status": {
        value: "Actionable Establishment",
        source: "EPFO",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Date Of Coverage": {
        value: "01-JUL-2019",
        source: "EPFO",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Primary Business Activity": {
        value: "Expert Services",
        source: "EPFO",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
    "Section Applicable": {
        value: "Other Establishments in which Employing 20 or more persons and Notified by the Central Govt.",
        source: "EPFO",
        isCorrect: true,
        correctedValue: '',
        reasonForDiscrepancy: '',
    },
}

const VerificationTable = () => {
    const [data, setData] = useState(initialVerificationData);

    const handleFieldChange = (attribute, field, newValue) => {
        const updatedData = { ...data };
        updatedData[attribute][field] = newValue;
        if (field === 'isCorrect' && newValue === true) {
            updatedData[attribute].correctedValue = '';
            updatedData[attribute].reasonForDiscrepancy = '';
        }
    
        setData(updatedData);

    };

    const handleClassOfCompanyChange = (attribute, value) => {
        const updatedData = { ...data };
        if (attribute === "Class of Company") {
            updatedData[attribute].correctedValue = value;
        } else if (attribute === "CIN/LLPIN Status" || attribute === "PAN Status" || attribute === "GST Status" || attribute === "Establishment Status" || attribute === "Registration Status On ECR Portal" || attribute === "Post Coverage Status" || attribute === "Excemption Status" || attribute === "Actionable Status") {
            updatedData[attribute].correctedValue = value;
        }
        setData(updatedData);
    };

    const fetchInfoFromAPI = async (attribute) => {
        // Simulate an API call (replace with your actual API call)
        const response = await fetch('your-api-endpoint-here');
        const result = await response.json();

        // Update the Information field with the API result
        const updatedData = { ...data };
        updatedData[attribute].value = result.information; // Assuming the API response has an "information" property
        setData(updatedData);
    };

    useEffect(() => {
        // Make the API call when the component mounts (you can change this to trigger the call as needed)
        fetchInfoFromAPI();
        // eslint-disable-next-line
    }, []);

    // eslint-disable-next-line
    {/*const printData = () => {
        console.log(data);
    };

    const resetData = () => {
        const resetData = Object.keys(data).reduce((acc, attribute) => {
            acc[attribute] = {
                ...data[attribute],
                isCorrect: true,
                correctedValue: '',
                reasonForDiscrepancy: '',
            };
            return acc;
        }, {});
        setData(resetData);
    };
*/}

    return (
        <div>
            <table className='collapsed-border'>
                <thead>
                    <tr>
                        <th className='col-2 text-left'>Attribute</th>
                        <th className='col-2 text-left'>Information</th>
                        <th className='col-1 text-center'>Source</th>
                        <th className='col-1 text-center'>Correct</th>
                        <th className='col-1 text-center'>Incorrect</th>
                        <th className='col-2'>Corrected Value</th>
                        <th className='col-3'>Reason for Discrepancy</th>
                    </tr>
                </thead>
                <tbody id="table-body">
                    {Object.keys(data).map((attribute) => (
                        <tr key={attribute}>
                            <td className='text-left side-heading'>{attribute}</td>
                            <td  className='text-left side-heading'>{data[attribute].value}</td>
                            <td className='side-heading'>{data[attribute].source}</td>
                            <td>
                                <input
                                    type="radio"
                                    name={`radioGroup${attribute}`}
                                    value="correct"
                                    checked={data[attribute].isCorrect}
                                    onChange={() => handleFieldChange(attribute, 'isCorrect', true)}
                                />
                            </td>
                            <td>
                                <input
                                    type="radio"
                                    name={`radioGroup${attribute}`}
                                    value="incorrect"
                                    checked={!data[attribute].isCorrect}
                                    onChange={() => handleFieldChange(attribute, 'isCorrect', false)}
                                />
                            </td>
                            <td>
                                {attribute === "Class of Company" ? (
                                    <select
                                        className='corrected-value-input'
                                        value={data["Class of Company"].correctedValue}
                                        onChange={(e) => handleClassOfCompanyChange(attribute,e.target.value)}
                                        disabled={data["Class of Company"].isCorrect}
                                    >
                                        <option value="">Select Class</option>
                                        <option value="Private Limited Company (Pvt.Ltd.)">Private Limited Company (Pvt.Ltd.)</option>
                                        <option value="Public Limited (Ttd.)">Public Limited Company (Ttd.)</option>
                                        <option value="One Person Company (OPC)">One Person Company (OPC)</option>
                                        <option value="Section 8 Company">Section 8 Company</option>
                                        <option value="Producer Company">Producer Company</option>
                                        <option value="Nidhi Company">Nidhi Company</option>
                                        <option value="Government Company">Government Company</option>
                                        <option value="Foreign Company">Producer Company</option>
                                        <option value="Listed Company">Nidhi Company</option>
                                        <option value="Unlisted Company">Government Company</option>
                                    </select>
                                ) 

                                : attribute === "CIN/LLPIN Status" ? (
                                    <select
                                        className='corrected-value-input'
                                        value={data["CIN/LLPIN Status"].correctedValue}
                                        onChange={(e) => handleClassOfCompanyChange(attribute, e.target.value)}
                                        disabled={data["CIN/LLPIN Status"].isCorrect}
                                    >
                                        <option value="">Select CIN Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Strike Off">Strike Off</option>
                                        <option value="Under Liquidation">Under Liquidation</option>
                                        <option value="Amalgamated">Amalgamated</option>
                                        <option value="Dissolved">Dissolved</option>
                                    </select>
                                )

                                : attribute === "PAN Status" ? (
                                    <select
                                        className='corrected-value-input'
                                        value={data["PAN Status"].correctedValue}
                                        onChange={(e) => handleClassOfCompanyChange(attribute, e.target.value)}
                                        disabled={data["PAN Status"].isCorrect}
                                    >
                                        <option value="">Select PAN Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Invalid">Invalid</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Surrendered">Surrendered</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Duplicate">Duplicate</option>
                                        <option value="Not Available">Not Available</option>
                                        <option value="Unverified">Unverified</option>
                                    </select>
                                )

                                : attribute === "GST Status" ? (
                                    <select
                                        className='corrected-value-input'
                                        value={data["GST Status"].correctedValue}
                                        onChange={(e) => handleClassOfCompanyChange(attribute, e.target.value)}
                                        disabled={data["GST Status"].isCorrect}
                                    >
                                        <option value="">Select GST Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Suspended">Suspended</option>
                                        <option value="Composition">Composition</option>
                                        <option value="Nil Rated">Nil Rated</option>
                                        <option value="Unregistered">Unregistered</option>
                                        <option value="Pending for Verification">Pending for Verification</option>
                                        <option value="Migrated">Migrated</option>
                                        <option value="Under Process">Under Process</option>
                                    </select>
                                )

                                : attribute === "Establishment Status" ? (
                                    <select
                                        className='corrected-value-input'
                                        value={data["Establishment Status"].correctedValue}
                                        onChange={(e) => handleClassOfCompanyChange(attribute, e.target.value)}
                                        disabled={data["Establishment Status"].isCorrect}
                                    >
                                        <option value="">Select Establishment Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Under Construction">Under Construction</option>
                                        <option value="Under Renovation">Under Renovation</option>
                                        <option value="Permanently Closed">Permanently Closed</option>
                                        <option value="Pending Approval">Pending Approval</option>
                                        <option value="Temporary Closure">Temporary Closure</option>
                                        <option value="Under New Management">Under New Management</option>
                                        <option value="Out of Business">Out of Business</option>
                                        <option value="Not Yet Open">Not Yet Open</option>
                                        <option value="Merged">Merged</option>
                                        <option value="Bankrupt">Bankrupt</option>
                                        <option value="Suspended">Suspended</option>
                                        <option value="Rebranded">Rebranded</option>
                                    </select>
                                )

                                : attribute === "Registration Status On ECR Portal" ? (
                                    <select
                                        className='corrected-value-input'
                                        value={data["Registration Status On ECR Portal"].correctedValue}
                                        onChange={(e) => handleClassOfCompanyChange(attribute, e.target.value)}
                                        disabled={data["Registration Status On ECR Portal"].isCorrect}
                                    >
                                        <option value="">Select Registration Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Expired">Expired</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Suspended">Suspended</option>
                                        <option value="Revoked">Revoked</option>
                                        <option value="Under Review">Under Review</option>
                                        <option value="Rejected">Rejected</option>
                                        <option value="Renewed">Renewed</option>
                                        <option value="Pending Verification">Pending Verification</option>
                                        <option value="In Process">In process</option>
                                        <option value="Not Applicable">Not Applicable</option>
                                        <option value="Approved">Approved</option>
                                        <option value="Lapsed">Lapsed</option>
                                        <option value="Under Investigation">Under Investigation</option>
                                    </select>
                                )

                                : attribute === "Post Coverage Status" ? (
                                    <select
                                        className='corrected-value-input'
                                        value={data["Post Coverage Status"].correctedValue}
                                        onChange={(e) => handleClassOfCompanyChange(attribute, e.target.value)}
                                        disabled={data["Post Coverage Status"].isCorrect}
                                    >
                                        <option value="">Select Post Coverage Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Renewed">Renewed</option>
                                        <option value="Lapsed">Lapsed</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Suspended">Suspended</option>
                                        <option value="Claim Filed">Claim Filed</option>
                                        <option value="Claim Denied">Claim Denied</option>
                                        <option value="Pending Investigation">Pending Investigation</option>
                                        <option value="Terminated">Terminated</option>
                                        <option value="Under Review">Under Review</option>
                                        <option value="Paid Out">Paid Out</option>
                                        <option value="Not Applicable">Not Applicable</option>
                                    </select>
                                )

                                : attribute === "Excemption Status" ? (
                                    <select
                                        className='corrected-value-input'
                                        value={data["Excemption Status"].correctedValue}
                                        onChange={(e) => handleClassOfCompanyChange(attribute, e.target.value)}
                                        disabled={data["Excemption Status"].isCorrect}
                                    >
                                        <option value="">Select Excemption Status</option>
                                        <option value="Granted">Granted</option>
                                        <option value="Pending Approval">Pending Approval</option>
                                        <option value="Not Applicable">Not Applicable</option>
                                        <option value="Revoked">Revoked</option>
                                        <option value="Expired">Expired</option>
                                        <option value="Denied">Denied</option>
                                        <option value="Conditional">Conditional</option>
                                        <option value="Under Review">Under Review</option>
                                        <option value="Automatic">Automatic</option>
                                        <option value="Limited Scope">Limited Scope</option>
                                        <option value="Temporary">Temporary</option>
                                        <option value="Suspended">Suspended</option>  
                                    </select>
                                )

                                : attribute === "Actionable Status" ? (
                                    <select
                                        className='corrected-value-input'
                                        value={data["Actionable Status"].correctedValue}
                                        onChange={(e) => handleClassOfCompanyChange(attribute, e.target.value)}
                                        disabled={data["Actionable Status"].isCorrect}
                                    >
                                        <option value="">Select Actionable Status</option>
                                        <option value="Open">Open</option>
                                        <option value="Pending">Pending</option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Resolved">Resolved</option>
                                        <option value="Closed">Closed</option>
                                        <option value="Escalated">Escalated</option>
                                        <option value="Deferred">Deferred</option>
                                        <option value="Rejected">Rejected</option>
                                        <option value="Assigned">Assigned</option>
                                        <option value="Under Review">Under Review</option>
                                        <option value="Waiting for Information">Waiting for Information</option>
                                        <option value="Overdue">Overdue</option>
                                        <option value="Needs Verification">Needs Verification</option> 
                                        <option value="Reopened">Reopened</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Urgent">Urgent</option>
                                        <option value="Scheduled">Scheduled</option>
                                    </select>
                                )          

                                : (
                                    <input
                                        type="text"
                                        className='corrected-value-input'
                                        value={data[attribute].correctedValue}
                                        onChange={(e) => handleFieldChange(attribute, 'correctedValue', e.target.value)}
                                        disabled={data[attribute].isCorrect}
                                    />
                                )}
                            </td>
                            <td>
                                <textarea
                                    rows="2"
                                    className='reason-for-discrepacy-textfield'
                                    maxLength={2000}
                                    value={data[attribute].reasonForDiscrepancy}
                                    onChange={(e) => handleFieldChange(attribute, 'reasonForDiscrepancy', e.target.value)}
                                    disabled={data[attribute].isCorrect}
                                    
                                ></textarea>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/*<button className="btn btn-primary" onClick={printData}>Print Data</button>
            <button className="btn btn-primary" onClick={resetData}>Reset</button>
                                */}
        </div>
    );
};

export default VerificationTable;